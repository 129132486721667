import { FC } from 'react'
import styled, { css } from 'styled-components'

import { Typography } from '@ensdomains/thorin'

import { cacheableComponentStyles } from '@app/components/@atoms/CacheableComponent'
import { SubgraphMetadataInfoResult } from '@app/hooks/ensjs/subgraph/useSubgraphMetadataInfo'
import { useNameDetails } from '@app/hooks/useNameDetails'

import { TabWrapper as OriginalTabWrapper } from '../../TabWrapper'

const Row = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `,
  cacheableComponentStyles,
)

const Text = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.colors.textPrimary};
    font-size: ${theme.fontSizes.body};
  `,
)

const TabWrapper = styled(OriginalTabWrapper)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: ${theme.space['4.5']};
  `,
  cacheableComponentStyles,
)

export const AgentTab = ({
  name,
  metadataInfo,
  nameDetails,
  isCached,
}: {
  name: string
  metadataInfo: SubgraphMetadataInfoResult | null | undefined
  nameDetails: ReturnType<typeof useNameDetails>
  isCached: boolean
}) => {
  const { expiryDate, wrapperData } = nameDetails

  const pre_status =
    ~~(Date.now() / 1000) <= Number(nameDetails.wrapperData?.expiry?.value.toString())
      ? 'Not Available'
      : 'Available'

  const { isValid } = nameDetails
  const status = isValid ? pre_status : 'Invalid name'

  const isSingleDomain = name.split('.').length == 2

  const preExpiry = new Date(expiryDate || wrapperData?.expiry?.date || 0).toLocaleDateString(
    'en-GB',
    {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
  )

  const expiry = isValid ? preExpiry : 'no expiry'

  return (
    <TabWrapper $isCached={isCached} data-testid="records-tab">
      <Row style={{ gap: 10, alignItems: 'flex-start' }}>
        <Expression type="HAPPY" imgURL={metadataInfo?.image} />
        <DomainMetaInfo
          domain={nameDetails.beautifiedName}
          expiry={expiry}
          status={isSingleDomain ? status : null}
        />
      </Row>
      <div style={{ marginTop: 20 }} />
      <Text style={{ fontWeight: 'bold' }}>{metadataInfo?.title}</Text>
      <div style={{ marginTop: 10 }} />

      <Text>{metadataInfo?.desc}</Text>
      <div style={{ marginTop: 10 }} />

      <KeyValue name="System Prompt" value={metadataInfo?.systemPrompt || ''} />
      <div style={{ marginTop: 10 }} />

      <KeyValue
        name="Intelligence files"
        value={metadataInfo?.intelligenceFiles?.join(', ') || ''}
      />

      <div style={{ marginTop: 10 }} />

      <KeyValue name="Websites" value={metadataInfo?.websites?.join(', ') || ''} />

      <div style={{ marginTop: 10 }} />

      <Text style={{ fontWeight: 'bold' }}>Prompts:</Text>
      {metadataInfo?.prompts &&
        metadataInfo?.prompts.length &&
        metadataInfo?.prompts?.map((prompt, index) => <Text key={index}>{prompt}</Text>)}
      {/* <Text>/This is another default prompt</Text> */}
    </TabWrapper>
  )
}

export const Expression: FC<{ type: 'SAD' | 'HAPPY'; imgURL?: string }> = ({ type, imgURL }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '120px',
        minHeight: '120px',
        maxWidth: '120px',
        maxHeight: '120px',
        fontSize: '3.5rem',
        color: '#707070',
        fontFamily: 'segoeui',
        letterSpacing: 0,
        // lineHeight: 0,
        // transform: "matrix(-0.12, 0.99, -0.99, -0.12, 0, 0)",
        border: '1px solid #707070',
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      {imgURL ? (
        <img
          src={imgURL}
          alt="3NS"
          style={{
            objectFit: 'contain',
            width: '100%',
            height: '100%',
          }}
        />
      ) : (
        <div
          style={{
            rotate: '105deg',
            fontSize: '4rem',
            marginLeft: 15,
          }}
        >
          {type == 'HAPPY' ? ':)' : ':('}
        </div>
      )}
    </div>
  )
}

const DomainMetaInfo = ({
  domain,
  status,
  expiry,
}: {
  domain: string
  status: string | null
  expiry: string
}) => {
  return (
    <div>
      <Text style={{ fontWeight: 'bold' }}>Domain name:</Text>
      <Text style={{ fontWeight: 'bold' }}>{domain}</Text>
      <div style={{ marginTop: 10 }} />

      {status && <KeyValue name="Status" value={status} />}
      {/* <KeyValue name="Registration Date" value="1st Jan 2024" /> */}
      {status && <KeyValue name="Expiry Date" value={expiry} />}
    </div>
  )
}

export const KeyValue: FC<{ name: string; value: string; color?: string }> = ({
  name,
  value,
  color,
}) => {
  return (
    <Text style={{ fontWeight: 'bold' }}>
      {name}:{' '}
      <Text
        style={{
          color,
          display: 'inline-block',
          fontWeight: color ? 'bold' : undefined,
        }}
      >
        {value}
      </Text>
    </Text>
  )
}
